import React from "react";
import { Typography } from "antd";
import { TextProps } from "antd/es/typography/Text";
import { TitleProps } from "antd/es/typography/Title";

const { Title, Text } = Typography;

interface TitleWithDescriptionProps {
  children: string;
  description?: string;
  titleProps?: TitleProps;
  descriptionProps?: TextProps;
}

export const TitleWithDescription = ({
  children,
  description,
  titleProps,
  descriptionProps,
}: TitleWithDescriptionProps) => {
  return (
    <>
      <Title
        level={1}
        style={{
          fontSize: 20,
        }}
        {...titleProps}
      >
        {children}
      </Title>
      {description && <Text {...descriptionProps}>{description}</Text>}
    </>
  );
};
